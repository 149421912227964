import React , {Component , useState}  from 'react';
import Animista, { AnimistaTypes } from "react-animista";
import styled from 'styled-components';
import { Row , Col  , Menu, Dropdown , Affix , Button } from 'antd';
import 'antd/dist/antd.css';
import {
  AlignLeftOutlined,
  CaretRightOutlined
} from '@ant-design/icons';
import {
  createMuiTheme,
  responsiveFontSizes,
  MuiThemeProvider,
  Typography
} from "@material-ui/core";
import Work from "./component/work";
import Tool from "./component/tools";
import Tlw from "./component/timelineWork";
import Member from "./component/member";
import team from './images/ourteam.png';
// import { Redirect , Link } from 'react-router-dom'


    let theme = createMuiTheme();
    theme = responsiveFontSizes(theme);

    const menu = (
      <Menu>
        <Menu.Item>
          <a href="#about">
            About Us
          </a>
        </Menu.Item>
        <Menu.Item>
          <a href="#work">
            Our Work
          </a>
        </Menu.Item>
        <Menu.Item>
          <a href="#tool">
            Our Tools
          </a>
        </Menu.Item>
        <Menu.Item>
          <a href="#team">
            Our Team
          </a>
        </Menu.Item> 
        <Menu.Item>
          <a href="/contact">
            Contact Us
          </a>
        </Menu.Item>       
        </Menu>
    );

    const FixMenu = () => {
      const [top] = useState(10);
      return (
        <>
          <Affix offsetTop={top}>
          <Dropdown overlay={menu}>
              <a className="ant-dropdown-link" href="/" onClick={e => e.preventDefault()}>
                <Align>
                  <AlignLeftOutlined />
                </Align>
              </a>
            </Dropdown>
          </Affix>
        </>
      );
    };

const Bg = styled.div`
  background: #2B313B;
`;
const text = " Our team is built from group of friends that want to improve themselves. We are eager and want to learn new thing that is aside from the knowledge that we studied in our major. We want to gain new experience include challenge ourselves. So, Dfellow is performed. ";


const Align = styled.div`
  color : white;
  font-size : 2.5vw;
  text-align : left;
  padding-left : 3%
`;


const BgW = styled.div`
  background: #EBE8D8;
`;
const BgG = styled.div`
    background-color: #BFDC36;
`;


const Title = styled.div`
  color: #EBE8D8;
  padding-top:0%; 
  font-family: Hatsch;
  text-align: right;
  font-size: 2vw;
  

  ${props => props.to && `
    color: #FF614C;
    font-family: lil;
    font-size: 3.5vw;
    margin-top :0%;
    margin-left: -10%;
    text-align: center;
  `}
  ${props => props.right && `
    color: #EBE8D8;
    font-family: Hatsch;
    text-align: left;
    width : 100%;
  `}
  @media only screen and (max-width: 600px) {
    font-size: 4vw;
    padding-top:20%; 
    ${props => props.to && `
      font-size: 5.5vw;
    `}
  }
`;

const Dfellow = styled.div`
  color: #FFDA1A;
  font-family: lil;
  font-size: 15vw;
  text-shadow: 2px 2px black;
  margin-top : -14%;
  margin-left :23%;
  text-align: left;
  ${props => props.team && `
  color: #EBE8D8;
  font-family: Hatsch;
  text-align: center;
  font-size: 7vw;
  margin-top : 15%;
  margin-left :32%;
  

`}
  @media only screen and (max-width: 600px) {
      font-size: 25vw;
      padding-top:0%; 
      margin-top: 40%;
      margin-left :10%;
      ${props => props.team && `
        position: absolute;
        font-size: 9vw;
        padding-top:0%; 
        margin-top : 45%;
        margin-left :65%;
      `}
  }

    /* text-shadow: 2px 2px #ff0000; */
  `;

/* const TriangleD = styled.div`  
  margin-top : -9%;
  @media only screen and (max-width: 600px) {
    margin-top : -18%;

  }
`;
const Triangle = styled.div`
  width: 0;
  height: 0;
  border-bottom: 100vw solid #36C9DC;
  border-bottom: calc(var(--vh, 1vh) * 100) solid #36C9DC;
  border-right: 55vw solid transparent; 
  border-right: calc(var(--vh, 1vh) * 55) solid transparent;    
  @media only screen and (max-width: 600px) {
    border-bottom: 20vw solid #36C9DC;
    border-bottom: calc(var(--vh, 1vh) * 100) solid #36C9DC;
    border-right: 25vw solid transparent; 
    border-right: calc(var(--vh, 1vh) * 25) solid transparent;    
  }

`;
const TriangleR = styled.div`
  width: 0;
  height: 0;
  border-bottom: 100vh solid #FF614C;
  border-left: 55vh solid transparent;      
  float : right;
  @media only screen and (max-width: 600px) {
    border-bottom: 100vh solid #FF614C;
    border-left: 55vh solid transparent;    
    border-left: calc(var(--vh, 1vh) * 25) solid transparent;    

  }
`; */
const Describe  = styled.div`
  color: #EBE8D8;
  font-family: Hatsch;
  text-align: right;
  font-size: 1.8vw;   
  margin-left : 1%;
  ${props => props.green && `
    color: #BFDC36;
    text-align: center;

  `} 
  ${props => props.left && `
    text-align: left;
  `} 
    @media only screen and (max-width: 600px) {
    font-size: 3vw;   
  }
`;
const DesDiv = styled.div`  
margin-top : 8%;
padding-bottom : 2%;
@media only screen and (max-width: 600px) {
  margin-top : 44%;

}
`;

const OurWork  = styled.div`

${props => props.our && `
  color: #EBE8D8;
  font-family: Hatsch;
  text-align: center;
  font-size: 4vw;  
  margin-top : 15%;
  margin-left : 12%;

`} 
${props => props.work && `
  color: #FF614C;
  font-family: lil;
  text-align: center;
  font-size: 12vw; 
  margin-top : -10%;

`} 
${props => props.process && `
  margin-top : 10%;

`} 
${props => props.processNum && `
  margin-top : 0%;
  color: #EBE8D8;
  font-family: Hatsch;
  font-size: 4vw; 
  position: relative;

`} 
${props => props.name && `

  color: #EBE8D8;
  padding-top :3%;
  padding-bottom :0%;
  font-family: Hatsch;
  font-size: 2vw; 

`} 
${props => props.date && `

  color: #EBE8D8;
  padding-bottom :0%;
  font-family: cormo;
  font-size: 2vw; 

`} 
${props => props.Pname && `

  color: #EBE8D8;
  font-family: Hatsch ;
  font-size: 6vw; 
  text-align : center;
  margin-top : 10%;


`} 
${props => props.des && `

color: #EBE8D8;
font-family: cormo ;
font-size: 1.5vw; 
text-align : center;
margin-top : 10%;


`} 

@media only screen and (max-width: 600px) {
${props => props.our && `
  font-size: 7vw;  
  margin-left : 17%;
`} 
${props => props.work && `
    font-size: 15vw; 
    text-align : center;
    margin-top : -13%;

`}

}
`;

const OurTool = styled.div`
${props => props.our && `
    font-size: 8vw; 
    text-align : center;
    font-family: lil;
    margin-left : -12%;
    color : #BFDC36;
    margin-top : -17%;

`}
${props => props.tool && `
    font-size: 8vw; 
    text-align : center;
    font-family: Hatsch;
    margin-left : 5%;
    color : #2B313B;


`}
`;

const AboutD  = styled.div`
  color: #2B313B;
  font-family: Hatsch;
  text-align: center;
  padding-top : 3%;
  font-size: 2vw;   
  padding-bottom : 3%;
  /* ${props => props.rotateR && `
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    margin-left : 80%;
  `} 
  ${props => props.rotateL && `
    transform: rotate(-270deg);
    -webkit-transform: rotate(-270deg);
    -moz-transform: rotate(-270deg);
    -ms-transform: rotate(-270deg);
    -o-transform: rotate(-270deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    margin-right : 80%;

  `}  */

`;
const AboutLogo  = styled.div`
color: #BFDC36;
font-family: Hatsch;
text-align: center;
padding-top : 8%;
font-size: 6.5vw;   
${props => props.under && `
    padding-top : 0%;
    max-width: 23%;
    height: 3vh;
    margin: auto;
    background: #FF614C;
    margin-left : 38%;
    margin-top : -2%;
  `} 
${props => props.us && `
    padding-top : 0%;
    color: #2B313B;
    font-family: lil;
    text-align: center;
    font-size: 18vw;  
    margin-top : -18%;
  `} 
${props => props.des && `
  
`} 


`;

const Contact = styled.div`  
  text-align : right;
  font-family: Hatsch;
  color : #EBE8D8;
  padding : 5%;

`;

const Pdes = styled.p`  
  padding-top : 10%;
  color: #2B313B;
  font-family: cormo;
  text-align: center;
  font-size: 1.5vw;  
  margin-top : -18%;
  padding-bottom : 8%;

`;


export default class home extends Component {

  
    
    render() {
      
        
      return (
        <div>
          <head>
            <title>Title of the document</title>
          </head>
          <Bg>
            <FixMenu></FixMenu>

            <Row>
              <Col span={9}>
                <Title >welcome</Title>
              </Col>
              <Col span={6}>
                <Title to>To</Title>
              </Col>
              <Col span={9}>
                <Title right>dfellow</Title>
              </Col>
            </Row>
            
            <Dfellow team>
                team
            </Dfellow>
            <Animista type={AnimistaTypes.SCALE_UP_TOP} duration="0.7s">
              <Dfellow>Dfellow</Dfellow>
            </Animista>
            
     
            <DesDiv>
              <Row>
                <Col span={10}>
                  <Describe>creative ,</Describe>
                </Col>
                <Col span={4}>
                  <Describe green>energetic</Describe>
                </Col>
                <Col span={10}>
                  <Describe left>, diligent</Describe>
                </Col>
              </Row>
            </DesDiv>
            
            
            <BgW>
              <AboutD>Dfellow</AboutD>
              <AboutLogo>About</AboutLogo>
              <AboutLogo under></AboutLogo>
              <Animista type={AnimistaTypes.SWING_BOTTOM_BCK} duration="1s" direction="reverse">
                <AboutLogo us id = "about">US</AboutLogo>
              </Animista>
              <Row>
                <Col span={6}>
                </Col>
                <Col span={12}>
                  <Animista type={AnimistaTypes.FLIP_DIAGONAL_1_BL} duration="1s" direction="reverse">
                    <Pdes>
                    <MuiThemeProvider theme={theme}>
                      <Typography variant="subtitle1" style={{fontFamily: "cormo"}} gutterBottom>
                        {text}
                      </Typography>
                    </MuiThemeProvider>
                    </Pdes>
                  </Animista>

                  
                </Col>
                <Col span={6}>
                </Col>
              </Row>
              <br></br>
              <AboutD>Dfellow</AboutD>
              
            </BgW>
            <Animista type={AnimistaTypes.SLIDE_LEFT} duration="1s" direction="reverse">
              <OurWork our id ="work">our</OurWork>
              <OurWork work>Work</OurWork>
            </Animista>
  
            <OurWork process>
              <Tlw></Tlw>
            </OurWork>
            <BgW>
              <br></br><br></br>
              <Animista type={AnimistaTypes.SLIDE_LEFT} duration="1s" direction="reverse">
                <OurTool tool id = "tool">tool</OurTool>
                <OurTool our>our</OurTool>
              </Animista>
              <br></br><br></br>
              <Animista type={AnimistaTypes.SLIDE_LEFT} duration="1s" delay="1s" direction="reverse">
                <Tool></Tool> 
              </Animista>
              <br></br><br></br>
            </BgW>
            <Work></Work>
            <BgW>
              <div id = "team">
                <img style={{ width : "35%",marginTop : "10%"}} src = {team} alt=""></img>
              </div>
              <br></br><br></br><br></br>
              <Member></Member>
              <Contact>
                <Button type="link" style = {{color : "#2B313B"}} href ="/contact/">
                  <span style = {{fontSize : "3vw"}}>Contact us</span>
                  <span style = {{fontSize : "3vw"}}><CaretRightOutlined /></span>
                </Button>
              </Contact>
            </BgW>

          </Bg>          
        </div>
      );
    }
  }