import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import axiosIn from '../AxiosIn';
import { LeftCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import 'antd/dist/antd.css'
import '../App.css'

function NewlineText(props) {
    const text = props.text;
    return text.split('\n').map(str => <p style={{margin: 0}}>{str}</p>);
}

const Wrapper = styled.div`
    display: flex-block;
    width: 100vw;
    min-height: 100vh;
    position: absolute;
    z-index:  -2;
    // overflow: auto;
    background-color: #2B313B;

`;

const BGCircle1 = styled.svg`
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0px;
    z-index: -1;
`;

const BGCircle2 = styled.svg`
    height: 120%;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: -1;
`;

const H1Position = styled.h1`
    // display: inline-block;
    // position: absolute;
    // left: 3vw;
    /* width: 20vw; */
    font-size: 8vw;
    color: #FFDA1A;
    align-self: center;
    text-shadow: 0px 3px 6px #000000;
    text-transform: uppercase;
`;

const ProfilePic = styled.img`
    width: 24vw;
    height: 32vw;
    object-fit: cover;
`;

const AboutBox = styled.div`
    text-align: center;
    color: #EBE8D8;
    font-family: Cormorant Garamond;
    font-size: 3vw; 
    padding: 3vh 0px;
    margin-top: 5vh;
    width: 100vw;
`;

const NameBox = styled.div`
    color: #2B313B;
    font-size: 2rem;
    margin-top: 2vh;
`;

const ProfileBox = styled.div`
    width: fit-content;
    height: fit-content;
    text-align: center;
    padding: 50px;
    margin: 0px;
    background-color: #EBE8D8;
`;

const Title1 = styled.h1`
    width: fit-content;
    height: auto;
    position: relative;
    z-index: 3;
    font-size: 14vw;
    font-family: Lil Stuart;
    color: ${props => props.Personal ? '#EBE8D8' : props.Skill ? '#36C9DC' : props.Projects ? '#FFDA1A' : '#000000' };
    text-align: left;
    text-shadow: 0px 3px 6px #2B313B;
    margin: 0px;
    margin-left: ${props => props.Personal ? 0 : props.Skill ? '30vw' : props.Projects ? 0 : 0 };
`;

const DataBox = styled.div`
    width: 70vw;
    position: relative;
    top: -5vh;
    z-index: 2;
    text-align: left;
    background-color: ${props => props.Personal ? '#BFDC36' : props.Skill ? '#FFDA1A' : props.Projects ? '#36C9DC' : '#000000' };
    // float: ${props => props.Personal ? 'left' : props.Skill ? 'left' : props.Projects ? 'left' : 'left' };
    padding: 5vh 2vw 2vh;
    margin-left: ${props => props.Personal ? 0 : props.Skill ? '30vw' : props.Projects ? 0 : 0 };
`;

const TextStyle = styled.p`
    text-align: left;
    text-transform: uppercase;
    font-size: 2vw;
    font-family: Hatsch sans;
    color: #2B313B;
`;

const ListText = styled.div`
    display: flex;

`;

export default class Profile2 extends React.Component {

    constructor(props) {
        super()
        this.state = {
            isLoading: false,
            Personal : '',
            project: [],
        }
    }
    // state = {
    //     Personal: '',
    // };

    componentDidMount() {
        const {match : {params}} = this.props;
        const header = {
            "Content-Type" : "application/json",
            "Accept" : "application/json"
        }
        axiosIn.get(`/profiles/${params.id}/`, {headers:header}).then( res => {
            // console.log(res.data.project)
            this.setState({ 
                Personal : res.data,
                isLoading : true,
                project : res.data.project,
             })
        }).catch( error => {
            console.log(error);
        })
    };

    render(){

        if(!this.state.isLoading) {
            return null;
        }
        else {
    
            return (
                <Wrapper>
                    
                    {/* <TopDiv> */}
                    <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10vh 0px 0px'}}>
                        <Col xs={15} md={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25%' }}>
                            <H1Position style={{ }}>
                                {this.state.Personal.member.position}
                            </H1Position>
                        </Col>
                        <Col xs={15} md={12} style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75%' }}>
                                <ProfileBox>
                                    <ProfilePic src={this.state.Personal.member.image.image} />
                                    <NameBox>
                                    {this.state.Personal.member.name} {this.state.Personal.member.surname}
                                    </NameBox>
                                </ProfileBox>
                                <BGCircle1 >
                                    <circle cx="80%" cy="30%" r="10vw" fill="#36C9DC" />
                                    <circle cx="20%" cy="80%" r="5vw" fill="#BFDC36" />
                                </BGCircle1>
                        </Col>
                    </Row>
                    {/* </TopDiv> */}
                    
                    <AboutBox>
                        <NewlineText text={this.state.Personal.about_me} />
                    </AboutBox>
    
                    <div style={{ widht: '100%' }}>
                        <div style={{ widht: '100%', position: 'relative' }}>
                            <Title1 Personal>
                                Personal Profile
                            </Title1>
                            <DataBox Personal>
                                <TextStyle>
                                    Name: {this.state.Personal.member.name} {this.state.Personal.member.surname}<br/>
                                    Nickanme: {this.state.Personal.member.nickname}<br/>
                                    Tel: {this.state.Personal.tel}<br/>
                                    e-mail: {this.state.Personal.mail}<br/>
                                    address: {this.state.Personal.address}<br/>
                                    <ListText>
                                        <div >
                                            education:
                                        </div>
                                        <div style={{ paddingLeft: '1rem' }}>
                                            <NewlineText text={this.state.Personal.education} />
                                        </div>
                                    </ListText>

                                </TextStyle>
                            </DataBox>
                        </div>
    
                        <div style={{ widht: '100%'}}>
                            <Title1 Skill>
                                Skill
                            </Title1>
                            <DataBox Skill>
                                <TextStyle>
                                    Programming Language: <br/>
                                    {this.state.Personal.programing_language}<br/>
                                </TextStyle>
                            </DataBox>
                        </div>
    
                        <div style={{ width: '100%', position: 'relative', marginBottom: '20vh' }}>
                            <Title1 Projects>
                                Project
                            </Title1>
                            <DataBox Projects>
                                {this.state.project.map(projectdata => (
                                    <TextStyle>
                                        {projectdata.name}<br/>
                                    </TextStyle>
                                ))}
                            </DataBox>
                        </div>
    
                    </div>
    
                    <BGCircle2>
                        <circle cx="20%" cy="95%" r="35vw" fill="#EBE8D8" />
                        <circle cx="80%" cy="98%" r="30vw" fill="#FF614C" />
                    </BGCircle2>
                </Wrapper>
            )
        }
        
    }
} 
