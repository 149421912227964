import axios from 'axios';
// Next we make an 'instance' of it
const instance = axios.create({
// .. where we make our configurations
    // baseURL: 'http://192.168.88.12:8000/api/v1/'
    baseURL: 'https://api.dfellow.com/api/v1/'
});

// Where you would set stuff like your 'Authorization' header, etc ...
// instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';

// // Also add/ configure interceptors && all the other cool stuff

// instance.interceptors.request.use(req => {
//     if (axios.defaults.headers.common["Authorization"]) return req;
//     throw ({message:"the token is not available"});
//    },error=>{
//     return Promise.reject(error);
//    }
//   );

//   instance.interceptors.response.use(response=>response,
//     error=>{
//       const fallbackValue = [
//         {userId: "Not authorized",id: "aerw15311sq",
//          title: "Please try     again",completed: false}];
//        return Promise.reject(fallbackValue);}
//     );

export default instance;
