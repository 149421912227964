import React , {Component}  from 'react';
import Animista, { AnimistaTypes } from "react-animista";
import { Row , Col ,Avatar } from 'antd';
import axiosWraper from "../AxiosWraper";
import styled from 'styled-components';
import 'antd/dist/antd.css';
import 'antd/dist/antd.css';



const OurWork  = styled.div`
${props => props.processNum && `
  margin-top : 0%;
  color: #EBE8D8;
  font-family: Hatsch;
  font-size: 4vw; 
  position: relative;

`} 
${props => props.name && `

  color: #EBE8D8;
  padding-top :3%;
  padding-bottom :0%;
  font-family: Hatsch;
  font-size: 2vw; 

`} 
@media only screen and (max-width: 600px) {


}
`;

const colors = ['#36C9DC', '#FF614C'];
export default class tool extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isLoaded: false,
        };
    }



    componentDidMount() {
        axiosWraper.get(`/project/`)
          .then(res => {  
            const data = res.data;
            this.setState({ 
                isLoaded: true,
                data,
            });
          });        
    }

  
    
    render() {
        const {isLoaded} = this.state;
        const datas = this.state.data ;
        if(!isLoaded){
            return null;
        }
        
        else{       
            return (
                <div>
                    <Row>
                        {datas.map((data,i)=> (
                            <Col xs={24} md={8} style={{paddingBottom : "5%"}} >
                                <Animista type={AnimistaTypes.SWING_BOTTOM_LEFT_BCK} delay={i/2 + "s"} duration="1s" direction="reverse">
                                    <OurWork processNum >-{i+1}-</OurWork> 
                                    <a href ={"#" + data.name}>
                                        <Avatar
                                        size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 80 }}
                                        style={{backgroundColor: colors[i%2] }}
                                        />
                                    </a> 
                                    <a href ={"#" + data.name}>
                                        <OurWork name>{data.name}</OurWork>
                                    </a>
                                </Animista>
                            </Col>
                        ))}                        
                    </Row>
                        
                      
                </div>
                 
      
      
            );

        }
      
      
    }
}

