import React , {Component}  from 'react';
import { Row , Col } from 'antd';
import axiosWraper from "../AxiosWraper";
import styled from 'styled-components';
import 'antd/dist/antd.css';
import 'antd/dist/antd.css';



const OurTool = styled.div`
${props => props.text && `
    font-size: 2vw; 
    text-align : center;
    font-family: sarabun;
    color : #2B313B;
    
`}
`;
export default class tool extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isLoaded: false,
        };
    }



    componentDidMount() {
        axiosWraper.get(`/tool/`)
          .then(res => {  
            const data = res.data;
            this.setState({ 
                isLoaded: true,
                data,
            });
          });        
    }

  
    
    render() {
        const {isLoaded} = this.state;
        const datas = this.state.data ;
        if(!isLoaded){
            return null;
        }
        
        else{       
            return (
                <div>
                    <Row>
                        {datas.map(data=> (
                            <Col xs={8} md={6}>
                                <div key={data.id}>
                                    <img src={data.image.image} style ={{width : "20%"}} alt= {data.name} ></img>
                                    <OurTool text>{data.name}</OurTool>
                                </div>
                            </Col> 
                        ))}                        
                    </Row>
                        
                      
                </div>
                 
      
      
            );

        }
      
      
    }
}

