import React , {Component}  from 'react';
import Animista, { AnimistaTypes } from "react-animista";
import { Row , Col , Avatar} from 'antd';
import styled from 'styled-components';
import axiosWraper from "../AxiosWraper";
import 'antd/dist/antd.css';
import 'antd/dist/antd.css';


const Wimg = styled.img`
    width : 70%;
    padding-bottom : 5%;
`;
const Bg = styled.div`
    background: #2B313B;
    border : none;
    margin-left : 3%;
    margin-right : 3%;
`;
const BgG = styled.div`
    background-color: #36C9DC;
    border : none;

`;
const OurWork  = styled.div`

${props => props.our && `
  color: #EBE8D8;
  font-family: Hatsch;
  text-align: center;
  font-size: 4vw;  
  margin-top : 15%;
  margin-left : 12%;

`} 
${props => props.work && `
  color: #FF614C;
  font-family: lil;
  text-align: center;
  font-size: 12vw; 
  margin-top : -10%;

`} 
${props => props.process && `
  margin-top : 10%;

`} 
${props => props.processNum && `
  margin-top : 0%;
  color: #EBE8D8;
  font-family: Hatsch;
  font-size: 4vw; 
  position: relative;

`} 
${props => props.name && `

  color: #EBE8D8;
  padding-top :3%;
  padding-bottom :0%;
  font-family: Hatsch;
  font-size: 2vw; 

`} 
${props => props.date && `

  color: #EBE8D8;
  padding-bottom :0%;
  font-family: cormo;
  font-size: 2vw; 

`} 
${props => props.Pname && `

  color: #EBE8D8;
  font-family: Hatsch ;
  font-size: 6vw; 
  text-align : center;
  margin-top : 10%;


`} 
${props => props.des && `

color: #EBE8D8;
font-family: cormo ;
font-size: 2vw; 
text-align : center;
margin-top : -5%;
margin-left : 5%;
margin-right : 5%;
margin-bottom : 5%;


`} 
${props => props.Sdes && `

color: #EBE8D8;
font-family: cormo ;
font-size: 2.5vw; 
text-align : center;
margin-top : 0%;


`} 

@media only screen and (max-width: 600px) {
${props => props.our && `
  font-size: 7vw;  
  margin-left : 17%;
`} 
${props => props.work && `
    font-size: 15vw; 
    text-align : center;
    margin-top : -13%;

`}

}
`;


export default class work extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isLoaded: false,
        };
    }

    componentDidMount() {
        axiosWraper.get(`/project/`)
          .then(res => {  
            const data = res.data;
            this.setState({ 
                isLoaded: true,
                data,
            });
          });        
    }

    
    render() {
        const {isLoaded} = this.state;
        const datas = this.state.data ;
        if(!isLoaded){
            return null;
        }
        
        else{      
            return (
                <BgG>
                    <br></br>
                    <Bg>
                    {datas.map((data,i)=> (
                        <div>
                            <Row>
                                <Col xs={24} md={12} style={{paddingBottom : "5%"}} >
                                    <Animista type={AnimistaTypes.SCALE_UP_TOP} duration="1s"  >
                                        <OurWork Pname id={data.name}>{data.name}</OurWork>
                                    </Animista>
                                    <Animista type={AnimistaTypes.SLIDE_LEFT} duration="1s" delay="0.5s" direction="reverse">
                                        <OurWork Sdes>" {data.short_description} "</OurWork>
                                    </Animista>
                                </Col>
                                <Col xs={24} md={12} style={{paddingBottom : "5%"}} >
                                </Col>
                            </Row>
                            <Row >
                                <Col xs={24} md={12} style={{paddingBottom : "5%"}} >
                                    <Animista type={AnimistaTypes.SCALE_UP_TOP} duration="1.5s" delay="1s" >
                                        <OurWork des>{data.description}</OurWork>
                                    </Animista>
                                </Col>
                                <Col xs={24} md={12} style={{paddingBottom : "5%",marginTop : "-5%"}} >
                                    {/* <a href={data.image.link}> */}
                                        {data.image.map(imgdata => (
                                        <div key={data.id}>
                                            <Animista type={AnimistaTypes.SCALE_UP_TOP} duration="1.5s" delay="1.5s" >
                                                <a href={data.link}>
                                                    <Wimg  src={imgdata.image} alt= {data.name}></Wimg>
                                                </a>
                                            </Animista>
                                        </div>
                                        ))}

                                    {/* </a> */}
                                </Col>
                            </Row>
                            <Row style={{marginTop : "0%"}}>
                                <Col xs={24} md={12} style={{paddingBottom : "5%"}} >
                                    <Avatar.Group >
                                        {data.member.map((memberdata,i)=> (
                                            <div key={memberdata.id}>
                                                <Avatar size={{ xs: 32, sm: 40, md: 40, lg: 64, xl: 80, xxl: 100 }} src={memberdata.image.image} />
                                            </div>
                                        ))}
                                    </Avatar.Group>                                
                                </Col>
                                <Col xs={24} md={12} style={{paddingBottom : "5%"}} >
                                </Col>
                            </Row>
                            <BgG><br></br></BgG>
                        </div>
                        ))}  
                    </Bg>
                    

                </BgG>
            ); 

        }
      
    }
}

