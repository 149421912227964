import React  from 'react';
import {Route} from 'react-router-dom'
import './App.css';
import Profile from './component/Profile_v2';
import Contact from './ContactUs';
import home from './home';

function App(){
    return(
        <div className="App" >
            <Route exact path="/" component={home} />
            <Route exact path="/profiles/:id" component={Profile} />
            <Route exact path="/contact/" component={Contact}  />
        </div>
    );
}

export default App;
