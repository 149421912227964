import React , {Component}  from 'react';
import { Row , Col , Avatar} from 'antd';
import axiosWraper from "../AxiosWraper";
// import Profile2 from './Profile_v2';
import Animista, { AnimistaTypes } from "react-animista";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import 'antd/dist/antd.css';



const OurTeam = styled.div`  
${props => props.name && `
    font-size: 2vw; 
    text-align : center;
    font-family: Hatsch;
    color : #36C9DC;


`}
${props => props.job && `
    font-size: 2vw; 
    text-align : center;
    font-family: cormo;
    color : #2B313B;
`}
`;

export default class tool extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isLoaded: false,
        };
    }



    componentDidMount() {
        axiosWraper.get(`/profiles/`)
          .then(res => {  
            const data = res.data;
            this.setState({ 
                isLoaded: true,
                data,
            });
            // console.log(this.state.data);
          });        
    }

  
    
    render() {
        const {isLoaded} = this.state;
        const datas = this.state.data ;
        
        if(!isLoaded){
            return null;
        }
        
        else{    

            return (
                <div>
                    <br></br><br></br>
                    <Row>
                        {datas.map(data=> (
                            
                            <Col xs={24} md={6} style={{paddingBottom : "5%"}} >
                                <Link to={`/profiles/${data.id}`}>
                                    <Animista type={AnimistaTypes.SCALE_UP_TOP} duration="1.5s" delay="1.5s" >
                                        <Avatar size={{ xs: 80, sm: 80, md: 80, lg: 120, xl: 140, xxl: 140 }} src={data.member.image.image} />
                                        <OurTeam name>{data.member.name}</OurTeam>
                                        <OurTeam name>{data.member.surname}</OurTeam>
                                        <OurTeam job>‘{data.member.position}‘</OurTeam>                                
                                    </Animista>
                                </Link>
                            </Col>
                        )
                        
                        )}                        
                    </Row>
                        
                      
                </div>
                 
      
      
            );

        }
      
      
    }
}

