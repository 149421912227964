import React, { Component } from 'react';
import { Form, Input, Button } from 'antd';
import styled from 'styled-components';
import { LeftCircleOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css'
import './App.css'
import pic1 from './pics/IMG_1789.png'
import pic2 from './pics/IMG_1790.png'
import axiosIn from './AxiosIn';
import { Link } from 'react-router-dom'

  const Title_contact = styled.h1`
  text-align: center;
  font-family:  'Lil Stuart';
  letter-spacing: 1px;
  color: #2B313B;
  opacity: 1;
  font-size: 9vw;
  margin-top: -12%;
  align-items: center;
  `;
  const Contact_form = styled.div`
  // text-align: center;
  font-family: 'SarabunExtraBold';
  // letter-spacing: 0px;
  color: #2B313B;
  opacity: 1;
  height: 100vh:
  font-size: 1vw;
  margin-top: -5%;
  margin-right: 10%;
  margin-left: 10%;
  // align-items: center;
  @media screen and (min-width: 575px) {
    margin-left: 3%;
    // align-items: center;
  }
  `;
class ContactUs extends Component {
  constructor() {
    super()
    this.state = {
      input: {},
      name: "",
      email: "",
      title: "",
      description: ""
    };
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange(event) {
    let input = this.state.input;
    // console.log(input);
    // console.log(event.target.name);
    input[event.target.name] = event.target.value;
    this.setState({
      input,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    let data = {
      name: this.state.input.name,
      email: this.state.input.email,
      title: this.state.input.title,
      description: this.state.input.description,
    }
    // console.log(data)
    axiosIn
      .post(`/contact/`, data)
      .then(res => {
        if(res.status === 201) {
          alert("sucess!!")
          this.setState({ isSuccess : true })
        }
      })
      .catch(error => {
        console.log(error.response)
        alert("Please try again");
      });
      if(this.validate()) {
        let input = {};
        input["name"] = "";
        input["email"] = "";
        input["title"] = "";
        input["description"] = "";
        this.setState({ input: input });
      }
  }
  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if(!input["name"]) {
      isValid = false;
      errors["name"] = "Please enter your name.";
    }

    if(!input["email"]) {
      isValid = false;
      errors["email"] = "Please enter your email.";
    }

    if(!input["title"]) {
      isValid = false;
      errors["title"] = "Please enter your title.";
    }

    if(!input["descrip"]) {
      isValid = false;
      errors["description"] = "Please enter your description.";
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }
  render() {
    const layout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };
    const onFinish = values => {
      console.log(values);
    };
    const validateMessages = {
      types: {
        email: this.state.email + ' is not validate email!'
      },
    };
    return (
      <div> 
        <Link to={`/`}>
        <LeftCircleOutlined className="back" style={{ fontSize: '6vw' }}/>
        </Link>
        <Title_contact className="title">Contact Us</Title_contact>
        <img className="pic1" src={pic1} alt="one"/>
        <Contact_form className="contact-form">
          <Form {...layout} name="nest-messages" onFinish={onFinish} onSubmit={this.handleSubmit} validateMessages={validateMessages}>
            <Form.Item label="NAME: ">
              <Input className="name" id="name" onChange={this.handleChange} value={this.state.input.name} name="name"/>
            </Form.Item>
            <Form.Item rules={[{ type: 'email' }]} label="E-MAIL: ">
                <Input className="email" id="email" onChange={this.handleChange} value={this.state.input.email} name="email"/>
            </Form.Item>
            <br/>
            <Form.Item label="SUBJECT: ">
              <Input id="title" onChange={this.handleChange} value={this.state.input.title} name="title"/>
            </Form.Item>
            <br/>
            <Form.Item label="DESCRIPTION: ">
              <Input.TextArea rows={5} id="description" style={{fontFamily: 'Sarabun'}} onChange={this.handleChange} value={this.state.input.description} name="description"/>
            </Form.Item>
            <br/>
            <Form.Item wrapperCol={{ ...layout.wrapperCol}}>
              <Button className="enter" type="primary" htmlType="submit" onClick={this.handleSubmit}>
                ENTER
              </Button>
          </Form.Item>
        </Form>
        </Contact_form>
        <img className="pic2" src={pic2} alt="two"/>
    </div>
    )
  }
}

export default ContactUs;